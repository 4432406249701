import { Col, Row } from "antd";
import React, { Component, useEffect, useState } from "react";

import { HeroImage } from "../assets/assests";
import Loader from "./Loader";

export default function About() {
  const [loading, setloading] = useState(false);

  useEffect(() => {
    document.title = "SoftOps - About";
    {
      redirectToOrderHistory();
    }
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 1000);
  }, []);

  const redirectToOrderHistory = () => {
    if (window.location.href.includes("/about")) {
      window.scrollTo(850, 0);
    }
  };

  const renderFeatureSection = () => {
    
    return (
      <section className="container feature_section about_feature_section">
        <Row className="container-flude">
          <Col lg={24}>
            <div className="feature_right_div">
              <div data-aos="fade-up" data-aos-duration="1000">
                <h2>About US</h2>
              </div>
            </div>
            <div className="feature_right_div">
              <div className="about_us">
                <p data-aos="fade-up" data-aos-duration="1000">
                  SoftOps Technologies is a trusted and highly certified
                  organisation providing IT consulting services and technology
                  solutions. Experienced crew centred on software program layout
                  and development.
                  <br />
                </p>
                <p data-aos="fade-up" data-aos-duration="2000">
                  Since 2020, we have been passionate about developing software
                  and products that meet different business needs with
                  transparent communication. We believe that businesses can
                  thrive and grow with a clear conscience.
                </p>
                <p data-aos="fade-up" data-aos-duration="3000">
                  SoftOps ensures the growth of the company's customers and
                  employees. That’s why our team works to ensure that the
                  projects they work on are impressive and successful. We have a
                  flexible, friendly, motivated, dedicated, and collaborative
                  work culture. In addition to software development, we focus on
                  educating and empowering the developer community by
                  researching polls and news to provide the latest trends and
                  best practices. Our goal is to improve the customer experience
                  by engaging and inspiring people worldwide.
                </p>
                <p data-aos="fade-up" data-aos-duration="4000">
                  <b>Let's do something great together!</b>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    );
  };
  return (
    <>
      {loading ? (
        <div className="spinner">
          <Loader />
        </div>
      ) : (
        <div>{renderFeatureSection()}</div>
      )}
    </>
  );
}
