import { Carousel, Col, Row } from "antd";
import React, { Component, useEffect, useState } from "react";
// @ts-ignore
import { Link } from "react-router-dom";
import { IoLogoReact } from "react-icons/io5";
import { SiNativescript } from "react-icons/si";
import { GoRuby } from "react-icons/go";
import { MdHighQuality } from "react-icons/md";
import Loader from "./Loader";
export default function Career() {
  const [loading, setloading] = useState(false);
  useEffect(() => {
    document.title = "SoftOps - Career";
    {
      redirectToOrderHistory();
    }
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 1000);
  }, []);

  const redirectToOrderHistory = () => {
    if (window.location.href.includes("/career")) {
      window.scrollTo(850, 0);
    }
  };

  const renderServiceSection = () => {
    return (
      <section
        id="#Services"
        className="header_down section-padding2 career_bg"
        style={{ position: "relative" }}
      >
        <div className="container">
          {/* <!-- Section Tittle --> */}
          <Row className="row d-flex justify-content-center">
            <div className="col-lg-6">
              <div className="section-tittle text-center">
                <h2
                  style={{ color: "white" }}
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                >
                  Join Our Forward-Thinking
                  <br />
                  Innovators
                </h2>
              </div>
            </div>
          </Row>
          {/* <!-- Section caption --> */}
          <Row gutter={16} className="row" justify="center">
            <Col
              lg={6}
              md={8}
              className="service-area"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="services-caption text-center mb-30">
                <div className="service-icon">
                  <SiNativescript className="flaticon-businessman" />
                </div>
                <div className="service-cap">
                  <h4>
                    <a href="javascript:void(0);">React Native</a>
                  </h4>

                  <p>
                    Qualification :- Btech (CS/IT) , MCA completed Good
                    communication skills
                  </p>
                </div>
                <div className="say-btn">
                  <Link to="/contact" className="btn radius-btn career_btn">
                    Apply Now
                  </Link>
                </div>
              </div>
            </Col>
            <Col
              lg={6}
              md={8}
              className="service-area"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div className="services-caption text-center mb-30">
                <div className="service-icon">
                  <IoLogoReact className="flaticon-businessman" />
                </div>
                <div className="service-cap">
                  <h4>
                    <a href="javascript:void(0);">React Js</a>
                  </h4>
                  <p>
                    Qualification :- Btech (CS/IT) , MCA completed Good
                    communication skills
                  </p>
                </div>
                <div className="say-btn">
                  <Link to="/contact" className="btn radius-btn career_btn">
                    Apply Now
                  </Link>
                </div>
              </div>
            </Col>

            <Col
              lg={6}
              md={8}
              className="service-area"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="services-caption text-center mb-30">
                <div className="service-icon">
                  <GoRuby className="flaticon-businessman" />
                </div>
                <div className="service-cap">
                  <h4>
                    <a href="javascript:void(0);">ROR(Ruby On Rails)</a>
                  </h4>
                  <p>
                    Qualification :- Btech (CS/IT) , MCA completed Good
                    communication skills
                  </p>
                </div>
                <div className="say-btn">
                  <Link to="/contact" className="btn radius-btn career_btn career_btn_diabled">
                    Apply Now
                  </Link>
                </div>
              </div>
            </Col>

            <Col
              lg={6}
              md={8}
              className="service-area"
              data-aos="fade-up"
              data-aos-duration="2500"
            >
              <div className="services-caption text-center mb-30">
                <div className="service-icon">
                  <MdHighQuality className="flaticon-businessman" />
                </div>
                <div className="service-cap">
                  <h4>
                    <a href="javascript:void(0);">QA & Testing</a>
                  </h4>
                  <p>
                    Qualification :- Btech (CS/IT) , MCA completed Good
                    communication skills
                  </p>
                </div>
                <div className="say-btn">
                  <Link to="/contact" className="btn radius-btn career_btn career_btn_diabled">
                    Apply Now
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    );
  };
  return (
    <div>
      {loading ? (
        <div className="spinner">
          <Loader />
        </div>
      ) : (
        <div>{renderServiceSection()}</div>
      )}
    </div>
  );
}
