import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import {ServicesData} from "./Employe"
 export default function Services() {
  const [loading, setloading] = useState(false);
  const [readMore, setreadMore] = useState(ServicesData);
  useEffect(() => {
    document.title = "SoftOps - Services";
    {
      redirectToOrderHistory();
    }
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 1000);
  }, []);
  const redirectToOrderHistory = () => {
    if (window.location.href.includes("/services")) {
      window.scrollTo(850, 0);
    }
  };

  const toggleReadMore = (serviceItem: any) => {
    let listOfServicesData = ServicesData;
    let a = listOfServicesData.map((element: any) => {
        if (element.id === serviceItem.id) {
          element.isSelected = !element.isSelected;
        } else {
          element.isSelected = false;
        }
        return element;
    });
    console.log("@@@ data",listOfServicesData);
    
    setreadMore(a);
    };

  const renderServiceSection = () => {
    return (
      <section
        id="#Services"
        className="header_down section-padding2"
        style={{ position: "relative" }}
      >
        {/* <!-- Section Tittle --> */}
        <Row justify="center">
          {/* <Col lg={18}> */}
          <Col lg={12}>
            <div className="section-tittle text-center">
              <h2 data-aos="fade-up" data-aos-duration="2000">
                How Can We HelpYou
                <br />
                with SoftOps!
              </h2>
            </div>
          </Col>
          {/* </Col> */}
        </Row>
        {/* <!-- Section caption --> */}
        <Row gutter={16} className="position_set">
          {/* <div className="position_set"> */}
          {readMore?.map((item:any)=>{return(<>
          <Col
            lg={6}
            md={8}
            sm={12}
            xs={24}
            className="service-area"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <div className="services-caption text-center mb-30">
              <div className="service-icon">
                {item.icon}
              </div>
              <div className="service-cap">
                <h4>
                  <a href="javascript:void(0);">{item.heading}</a>
                </h4>
                <span>
                  {item.isSelected ? (
                    <p>
                      {item.data}
                      {/* {item.data.slice(0,100)} */}
                    </p>
                  ) : (
                    <p>
                      {/* {item.data} */}
                      {item.data.slice(0,90)}
                    </p>
                  )}
                  {item.isSelected ? (
                    <span
                      className="span_cursor_less"
                      onClick={() => toggleReadMore(item)}
                    >
                      ....Show Less
                    </span>
                  ) : (
                    <span
                      className="span_cursor"
                      onClick={() => toggleReadMore(item)}
                    >
                      ....Read More
                    </span>
                  )}
                </span>
              </div>
            </div>
          </Col>
          </>)})}
          {/* </div> */}
        </Row>
      </section>
    );
  };
  return (
    <div>
      {loading ? (
        <div className="spinner">
          <Loader />
        </div>
      ) : (
        <>{renderServiceSection()}</>
      )}
    </div>
  );
}
