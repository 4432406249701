import { Carousel, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { App11, App12, App13, App14, App15} from "../assets/assests";
// @ts-ignore

import {
  HeroImage,
  App1,
  App2,
  App3,
  shap1,
  shap2,
  shap3,
  Availabel,
  App4,
  App5,
  App7,
  App8,
  App9,
  App10,
} from "../assets/assests";
import {
  DotChartOutlined,
  FireOutlined,
  FontSizeOutlined,
  MedicineBoxOutlined,
} from "@ant-design/icons";
import ContactHeroMain from "./ContactHeroMain.web";
import Loader from "./Loader";
//Aos Effect
//@ts-ignore
import aos from "aos";
import "aos/dist/aos.css";
import {ServicesData} from "./Employe"
export default function Home() {
  const Data = [
    { image: App1 },
    { image: App4 },
    { image: App2 },
    { image: App5 },
    { image: App3 },
    { image: App7 },
    { image: App8 },
    { image: App9 },
    { image: App10 },
    { image: App11 },
    { image: App12 },
    { image: App13 },
    { image: App14 },
    { image: App15 },
  ];
  const [loading, setloading] = useState(false);
  const [NumberCaurasel, setNumberCaurasel] = useState(3);
  const [readMore, setreadMore] = useState(ServicesData);
  useEffect(() => {
    console.log("@@@ ineer width", window.innerWidth <= 420);
    if (window.innerWidth <= 420 || window.innerWidth <= 820) {
      setNumberCaurasel(1)
    }

    {
      redirectToOrderHistory();
    }
    setloading(true);
    setTimeout(() => {
      document.title = "SoftOps - Home";
      setloading(false);
      aos.init({
        easing: "ease-out-back",
        duration: 1500,
      });
    }, 1000);
  }, []);
  const redirectToOrderHistory = () => {
    if (window.location.href.includes("/")) {
      window.scrollTo(850, 0);
    }
  };
  const toggleReadMore = (serviceItem: any) => {
    let listOfServicesData = ServicesData;
    console.log("@@@ ",listOfServicesData);
    
    let a = listOfServicesData.map((element: any) => {
        if (element.id === serviceItem.id) {
          element.isSelected = !element.isSelected;
        } else {
          element.isSelected = false;
        }
        return element;
    });
    console.log("@@@ data",listOfServicesData);
    
    setreadMore(a);
    };
  const renderHeaderSection = () => {
    return (
      <section className="header_down">
        <Row className="container">
          <Col lg={12} md={12} data-aos="fade-up">
            <div className="Home_content_left">
              <div>
                <span>SoftOps</span>
                <h1>
                  Your Dream Delivered with SoftOps
                  <br />
                  {/* with SoftOps */}
                </h1>

                <p>
                  SoftOps Technologies is a trusted and highly accredited
                  organisation that provides IT consultancy services and
                  technology solutions.
                </p>
              </div>
            </div>
          </Col>

          <Col lg={12} md={12} data-aos="fade-up">
            <img src={HeroImage} alt="HeroImage" />
          </Col>
        </Row>
      </section>
    );
  };

  const renderFeatureSection = () => {
    return (
      <section className="feature_section">
        <Row className="container-flude">
          <Col lg={8} md={6} data-aos="fade-up" data-aos-duration="2000">
            <img className="feature_img" src={Availabel} alt="" />
          </Col>
          <Col lg={16} md={18} data-aos="fade-up">
            <div className="feature_right_div">
              <div>
                <h2>
                  Some of the best domains <br /> Of Our Apps!
                </h2>
              </div>
            </div>
            <div className="feature_right_div">
              <Row gutter={16}>
                <Col lg={12} md={12} data-aos="fade-up" data-aos-duration="1500">
                  <div className="feature_text_header">
                    <div className="single-features">
                      <div className="features-icon">
                        <DotChartOutlined className="flaticon_support" />
                      </div>
                      <div className="features_caption">
                        <h3>E-Commerce</h3>
                        <p>
                          Providing an immersive and unique experience that
                          makes online shopping a much simpler and seamless
                          process.
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12} md={12} data-aos="fade-up" data-aos-duration="2000">
                  <div className="feature_text_header">
                    <div className="single-features mb-70">
                      <div className="features-icon">
                        <FontSizeOutlined className="flaticon_support" />
                      </div>
                      <div className="features_caption">
                        <h3>E-Learning</h3>
                        <p>
                          An interactive webpage that allows learners to input
                          their data and get expected results through
                          interactions.
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12} md={12} data-aos="fade-up" data-aos-duration="2500">
                  <div className="feature_text_header">
                    <div className="single-features mb-70">
                      <div className="features-icon">
                        <MedicineBoxOutlined className="flaticon_support" />
                      </div>
                      <div className="features_caption">
                        <h3>Medical</h3>
                        <p>
                          A portable outlet to the internal knowledge base,
                          created with the help of a facility's healthcare
                          specialists and stored on a separate secured server.
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12} md={12} data-aos="fade-up" data-aos-duration="3000">
                  <div className="feature_text_header">
                    <div className="single-features mb-70">
                      <div className="features-icon">
                        <FireOutlined className="flaticon_support" />
                      </div>
                      <div className="features_caption">
                        <h3>Food App</h3>
                        <p>
                          Helps the customer to access the online food ordering
                          platforms, search for the right restaurant or the dish
                          they want to order
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>
    );
  };

  const renderAppScreenshotSection = () => {
    return (
      <section className="feature_section">
        <div className="container-fluid">
          <Row className="row">
            {/* <!-- slider Heading --> */}
            <Col lg={9} md={8}>
              <div className="single-cases-info mb-30">
                <h3 data-aos="fade-up" data-aos-duration="1000">
                  Things We Are
                  <br /> Doing
                </h3>
                <p data-aos="fade-up" data-aos-duration="3000">
                  At SoftOps, we've always tried to do things a little
                  differently. We believe that a company can grow with a clear
                  conscience and succeed with a soul.{" "}
                </p>
              </div>
            </Col>
            {/* <!-- OwL --> */}
            <Col lg={15} md={16} data-aos="zoom-in" data-aos-duration="1500">
              <div className="owl-carousel ">
                <Carousel slidesToShow={4} autoplay className="hidetext">
                  {Data.map((item: any) => {
                    return (
                      <div>
                        <img src={item.image} alt="" />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    );
  };

  const renderOurCustomerSection = () => {
    return (
      <section className="feature_section">
        <div className="container">
          {/* <!-- Section Tittle --> */}
          <Row className="row d-flex justify-content-center">
            <div className="col-lg-6">
              <div className="section-tittle text-center">
                <h2 data-aos="fade-up" data-aos-duration="1000">
                  See What Our Customers
                  <br /> Have to Say
                </h2>
              </div>
            </div>
          </Row>
          <Row className="row">
            {/* <!-- OwL --> */}
            <Col lg={24}>
              <div className="owl-carousel ">
                <Carousel slidesToShow={NumberCaurasel} className="hidetext our_cus" >
                  <div className="main_customer">
                    <div className="our_customer_main">
                      <div className="image_div_ourcustomer">
                        <img
                          src={shap1}
                          className="our_customer_main"
                          alt="App1"
                        />
                      </div>
                      <div className="what-cap">
                        <h4>
                          <a href="javascript:void(0);">
                            Sara Vilson
                          </a>
                        </h4>
                        <p>
                          This is a simple modality employed across all business industries, but SoftOps excels at efficient non-invasive survey delivery as well as well-trained and pleasant support staff.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="main_customer">
                    <div className="our_customer_main">
                      <div className="image_div_ourcustomer">
                        <img
                          src={shap2}
                          className="our_customer_main"
                          alt="App1"
                        />
                      </div>
                      <div className="what-cap">
                        <h4>
                          <a href="javascript:void(0);">
                            Saul Goodman
                          </a>
                        </h4>
                        <p>
                        Great to work with, I didn’t have very much experience in mobile app development but SoftOps helped me through it and the product came out terrific. I am planning additional projects for them now.                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="main_customer">
                    <div className="our_customer_main">
                      <div className="image_div_ourcustomer">
                        <img
                          src={shap3}
                          className="our_customer_main"
                          alt="App1"
                        />
                      </div>
                      <div className="what-cap">
                        <h4>
                          <a href="javascript:void(0);">
                            John Larson
                          </a>
                        </h4>
                        <p>
                        Its been 2 years working with SoftOps and they are our go-to team for all our development work they are Professional, attentive, and work well with complex project specifications. Hope to continue the relationship.                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="main_customer">
                    <div className="our_customer_main">
                      <div className="image_div_ourcustomer">
                        <img
                          src={shap1}
                          className="our_customer_main"
                          alt="App1"
                        />
                      </div>
                      <div className="what-cap">
                        <h4>
                          <a href="javascript:void(0);">
                            Jena Karlis
                          </a>
                        </h4>
                        <p>
                        SoftOps is a resourceful and dedicated professional that works well with a technical team and finds creative solutions to problems. I plan on hiring them again as soon as I have more work for them.                        </p>
                      </div>
                    </div>
                  </div>
                </Carousel>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    );
  };

  const renderServiceSection = () => {
    return (
      <section
        id="#Services"
        className="header_down section-padding2"
        style={{ position: "relative" }}
      >
        {/* <!-- Section Tittle --> */}
        <Row justify="center">
          {/* <Col lg={18}> */}
          <Col lg={12}>
            <div className="section-tittle text-center">
              <h2 data-aos="fade-up" data-aos-duration="2000">
                How Can We HelpYou
                <br />
                with SoftOps!
              </h2>
            </div>
          </Col>
          {/* </Col> */}
        </Row>
        {/* <!-- Section caption --> */}
        <Row gutter={16} className="position_set">
          {/* <div className="position_set"> */}
          {readMore?.map((item:any)=>{return(<>
          <Col
            lg={6}
            md={8}
            sm={12}
            xs={24}
            className="service-area"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <div className="services-caption text-center mb-30">
              <div className="service-icon">
                {item.icon}
              </div>
              <div className="service-cap">
                <h4>
                  <a href="javascript:void(0);">{item.heading}</a>
                </h4>
                <span>
                  {item.isSelected ? (
                    <p>
                      {item.data}
                      {/* {item.data.slice(0,100)} */}
                    </p>
                  ) : (
                    <p>
                      {/* {item.data} */}
                      {item.data.slice(0,90)}
                    </p>
                  )}
                  {item.isSelected ? (
                    <span
                      className="span_cursor_less"
                      onClick={() => toggleReadMore(item)}
                    >
                      ....Show Less
                    </span>
                  ) : (
                    <span
                      className="span_cursor"
                      onClick={() => toggleReadMore(item)}
                    >
                      ....Read More
                    </span>
                  )}
                </span>
              </div>
            </div>
          </Col>
          </>)})}
          {/* </div> */}
        </Row>
      </section>
    );
  };
  return (
    <div>
      {loading ? (
        <div className="spinner">
          <Loader />
        </div>
      ) : (
        <>
          {renderHeaderSection()}
          {renderFeatureSection()}
          {renderServiceSection()}
          {renderAppScreenshotSection()}
          {renderOurCustomerSection()}
          <ContactHeroMain />
        </>
      )}
    </div>
  );
}
