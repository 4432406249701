import React, { Component } from 'react'
import { LoaderGif,LOGO } from "../assets/assests";
export class Loader extends Component {
  render() {
    return (
        <div className='spinner'>
          <div className='div_main_loader'>
          <img src={LoaderGif} alt="LoaderGif" className='loader_main_img'/>
          <img src={LOGO} alt="" className="loader_Set"  />
          </div>
        </div>
    )
  }
}

export default Loader