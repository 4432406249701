//@ts-nocheck
import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from 'react-router-dom';
import Footer from './Components/Footer.web';
import Header from './Components/Header.web';
/** Routes List start */

import Home from './Components/Home.web';
import About from './Components/About.web';
import MeetTheTeam from './Components/MeetTheTeam.web';
import Services from './Components/Services.web';
import Career from './Components/Career.web';
import Gallery from './Components/Gallery.web';
import Contact from './Components/Contact.web';

// import Loader from '../src/Components/Loader';


function WebRoutes() {
  // render() {
  return (
    <Router>
      <Suspense>
      <Header/>
        <Switch>
          <Route path="/" exact render={props => <Home {...props} />} />
          <Route path="/about" exact render={props => <About {...props} />} />
          <Route path="/meet-the-team" exact render={props => <MeetTheTeam {...props} />} />
          <Route path="/services" exact render={props => <Services {...props} />} />
          <Route path="/career" exact render={props => <Career {...props} />} />
          <Route path="/gallery" exact render={props => <Gallery {...props} />} />
          <Route path="/contact" exact render={props => <Contact {...props} />} />
        </Switch>
        <Footer/>
      </Suspense>
    </Router>
  );
  // }
}

//@ts-ignore
export default WebRoutes;
