import { GoDeviceMobile } from "react-icons/go";
import { AiOutlineFormatPainter } from "react-icons/ai";
import { BiDevices } from "react-icons/bi";
import { FaProductHunt } from "react-icons/fa";
import { RiTeamLine } from "react-icons/ri";
import { MdHighQuality } from "react-icons/md";
import {
  Team1,
  Team2,
  Team3,
  Team4,
  Team5,
  Team6,
  Team7,
  Team8,
  Team9,
  Team10,
  Team11,
  Team12,
  Team14,
  Team15,
  Team16,
  Team17,
  Team18,
  Team19,
  Team20,
  Team21,
  Team22,
  Team24,
  Team25,
  Teampic1, Teampic2, Teampic3, Teampic4, Teampic6, Teampic7, Teampic8, Teampic9, Teampic10, Teampic11, Teampic12, Teampic13, Teampic14, Teampic15, Teampic16, Teampic17, Teampic18, Teampic19, Teampic20, Teampic21, Teampic22, Teampic23, Teampic24, Teampic25, Teampic26, Teampic27, Teampic28, Teampic29, Teampic30, Teampic31, Teampic32, Teampic33, Teampic34, Teampic35, Teampic36, Teampic37, Teampic38, Teampic39, Teampic40, Teampic41, Teampic42, Teampic43, Teampic44, Teampic45, Teampic46, Teampic47, Teampic48, Team26, Team27, Team28, Team29, Team30, Team31, Team32, Team33, Team34, Team35, Team36, Team37, Team38, Team39, Team40, Team41, Team42, Teampic49, Teampic50, Teampic51, Teampic52, Team43, Team44, Teampic53, Teampic54, Teampic55, Teampic56, Teampic57, Teampic58, Teampic59, Teampic60, Teampic61, Team45, Team46, Team47, Team48, Team49, Team50, Team51, Team53, Team54, Team55
} from "../assets/assests"
export const EmployeListData = [
  { Name: "Rituraj Mandloi", image: Team10, Designation: "Team Lead", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Akhilesh Jaiswal", image: Team2, Designation: "Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Anmol Karotiya", image: Team3, Designation: "Associate Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Sumit Barfa", image: Team4, Designation: "Associate Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Gaurav Singh", image: Team5, Designation: "Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Himanshu Joshi", image: Team6, Designation: "Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Vivek Mishra", image: Team7, Designation: "Associate Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Hitesh Verma", image: Team8, Designation: "Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Nancy Shrivastava", image: Team9, Designation: "Human Resource", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Rohit Yadav", image: Team11, Designation: "Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Saubhagya Singh Chandel", image: Team12, Designation: "Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Nimish Vikhar", image: Team14, Designation: "Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Rahul kirar", image: Team15, Designation: "Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Ravi", image: Team16, Designation: "Associate Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Akhilesh Verma", image: Team1, Designation: "Associate Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Shalini Prajapati", image: Team25, Designation: "Associate Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Sonali Solanki", image: Team18, Designation: "Ex - Associate Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Sumit Belsare", image: Team19, Designation: "Associate Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Sumit Choudhary", image: Team20, Designation: "Associate Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Devendra Rathore", image: Team21, Designation: "Associate Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Hitendra Tomar", image: Team22, Designation: "Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Madhuri Bourasi", image: Team24, Designation: "Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Prachi Gehlot", image: Team26, Designation: "Trainee", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Lily Ora", image: Team27, Designation: "Trainee", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Khus Singhal", image: Team28, Designation: "Trainee", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Himanshu Rathore", image: Team29, Designation: "Trainee", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Deepali Katare", image: Team30, Designation: "Trainee", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Ravi Yadav", image: Team31, Designation: "Office Boy", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Sawan Saini", image: Team32, Designation: "Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Abhilash", image: Team33, Designation: "Associate Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Diksha Patel", image: Team34, Designation: "Associate Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Sakshi Sahu", image: Team35, Designation: "EX - Associate Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Adarsh Pandey", image: Team36, Designation: "Associate Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Pankaj Babu", image: Team37, Designation: "Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Chetna Dhakad", image: Team38, Designation: "Trainee", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Vaishali Kevat", image: Team39, Designation: "Ex - Sr Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Rudransh Solanki", image: Team40, Designation: "Trainee", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Devendra Pratap Singh", image: Team41, Designation: "Trainee", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Aditya Singh", image: Team42, Designation: "Trainee", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Pravin Prajapati", image: Team43, Designation: "Trainee", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Anurag Kanugo", image: Team44, Designation: "Trainee", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Sonam Asati", image: Team45, Designation: "Trainee", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Adarsh Sihare", image: Team46, Designation: "Trainee", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Tarun Kumar Raikwar", image: Team47, Designation: "Trainee", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  // { Name: "Shaleen Singh Makhani", image: Team48, Designation: "Trainee", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Chandrabhan Singh", image: Team49, Designation: "Trainee", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Roshni Yadav", image: Team50, Designation: "Trainee", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Yash Verma", image: Team51, Designation: "Software Engineer", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Arun Pawar", image: "", Designation: "Trainee", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Shefali Sahu", image: Team53, Designation: "QA (Manual Testing)", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Shraddha Choudhary", image: Team54, Designation: "QA (Manual Testing)", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
  { Name: "Priyanka Bahuguna", image: Team55, Designation: "QA (Manual Testing)", feedback: " Utenim ad minim veniam quisnostrud exercitationullamcolabor nisiut aliquip ex ea commodoconsequat duis aute irure dolor in represse." },
];
export const GallaryImage = [
  { image: Teampic61 },
  { image: Teampic60 },
  { image: Teampic59 },
  { image: Teampic58 },
  { image: Teampic57 },
  { image: Teampic56 },
  { image: Teampic55 },
  { image: Teampic54 },
  { image: Teampic53 },
  { image: Teampic51 },
  { image: Teampic49 },
  { image: Teampic50 },
  { image: Teampic52 },
  { image: Teampic1 },
  { image: Teampic2 },
  { image: Teampic3 },
  { image: Teampic4 },
  { image: Teampic6 },
  { image: Teampic7 },
  { image: Teampic8 },
  { image: Teampic9 },
  { image: Teampic10 },
  { image: Teampic11 },
  { image: Teampic12 },
  { image: Teampic13 },
  { image: Teampic14 },
  { image: Teampic15 },
  { image: Teampic16 },
  { image: Teampic17 },
  { image: Teampic18 },
  { image: Teampic19 },
  { image: Teampic20 },
  { image: Teampic21 },
  { image: Teampic22 },
  { image: Teampic23 },
  { image: Teampic24 },
  { image: Teampic25 },
  { image: Teampic26 },
  { image: Teampic27 },
  { image: Teampic28 },
  { image: Teampic29 },
  { image: Teampic30 },
  { image: Teampic31 },
  { image: Teampic32 },
  { image: Teampic33 },
  { image: Teampic34 },
  { image: Teampic35 },
  { image: Teampic36 },
  { image: Teampic37 },
  { image: Teampic38 },
  { image: Teampic39 },
  { image: Teampic40 },
  { image: Teampic41 },
  { image: Teampic42 },
  { image: Teampic43 },
  { image: Teampic44 },
  { image: Teampic45 },
  { image: Teampic46 },
  { image: Teampic47 },
  { image: Teampic48 },
];
export const ServicesData = [
  { icon:<GoDeviceMobile className="flaticon-businessman" /> , id: 1, isSelected:false, heading:"Mobile Development", data:"Meet your demanding deadlines with our mobile app development services that converge a mobile platform, custom development, and the latest tech." },
  { icon:<AiOutlineFormatPainter className="flaticon-businessman" /> , id: 2, isSelected:false,heading:"UX/UI Design", data:"Our services in software development allow you to create the products as you envisioned it with a mature design team. We rely on established standards and ten-year expertise." },
  { icon:<BiDevices className="flaticon-businessman" /> , id: 3, isSelected:false, heading:"Web Development",  data:"Web development services help create all types of web-based software and ensure great experience for web users." },
  { icon:<FaProductHunt className="flaticon-businessman" />,  id: 4, isSelected:false,  heading:"Product Engineering", data:"Use a powerful combination of profound tech expertise, mature, low-risk processes, and proven experience in a variety of business domains to turn your business idea into reality." },
  { icon:<RiTeamLine className="flaticon-businessman" />,  id: 5, isSelected:false,  heading:"Dedicated Development Teams", data:"Quickly hire the right tech talent for your project with our decade-long expertise. We will help you build a loyal and easily scalable software development team." },
  {  icon:<MdHighQuality className="flaticon-businessman" />, id: 6, isSelected:false,  heading:"QA & Testing", data:"Automated software testing, established standards, streamlined Q&A testing lifecycle — use our testing services to ensure the smooth operation of your software services." },
];
