import { Col, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import Loader from "./Loader";
import "./styles.css";
// @ts-ignore
import Hexagon from "react-hexagon";
import { GallaryImage } from "./Employe";

export default function Gallery() {
  const redirectToOrderHistory = () => {
    if (window.location.href.includes("/about")) {
      window.scrollTo(850, 0);
    }
  };

  const [hexagonData, setHexagonData] = useState<any>([]);
  const [loading, setloading] = useState(false);
  const [IMGModal, setModal] = useState(false);
  const [ModalImg, setModalImg] = useState("");

  useEffect(() => {
    document.title = "SoftOps - Gallery";
    {
      redirectToOrderHistory();
    }

    setloading(true);

    setTimeout(() => {
      setloading(false);
    }, 1000);

    let data = GallaryImage;
    let mainData = [];
    let countData = [];
    let count = 0;

    for (let i = 0; i < data.length; i++) {
      count += 1;
      if (count > 9) {
        mainData.push({
          data: countData,
        });
        count = 1;
        countData = [];
      }
      countData.push(data[i]);
    }
    if (countData.length > 0) {
      mainData.push({
        data: countData,
      });
    }
    setHexagonData(mainData);
    console.log("@@@", mainData);
  }, []);

  const onclick = (item: any) => {
    console.log("@@@ item", item);
    setModalImg(item.image);
    setModal(true);
  };

  const oncancelmodal = () => {
    console.log("@@@ item");
    setModal(false);
  };
  return (
    <>
      {loading ? (
        <div className="spinner">
          <Loader />
        </div>
      ) : (
        <div className="hexagonContainer">
          {hexagonData.map((hexItem: any, index: any) => {
            console.log("@@@");

            let row1Items =
              hexItem.data.length > 2
                ? hexItem.data.slice(0, 5).length
                : hexItem.data;
            let row2Items =
              hexItem.data.length > 2
                ? hexItem.data.slice(5).length
                : hexItem.data;
            return (
              <>
                <div
                  className={row1Items.length > 2 ? "firstRow" : "firstRowTop"}
                >
                  {hexItem.data.slice(0, 5).map((item: any, index: any) => {
                    return (
                      <Hexagon
                        onClick={() => onclick(item)}
                        style={{ stroke: "#fee9ff", "stroke-width": 10 }}
                        backgroundImage={item.image}
                        diagonal={100}
                        className={"hexagon"}
                        backgroundScale={0.0}
                      />
                    );
                  })}
                </div>
                <div
                  className={row1Items.length > 2 ? "firstRow" : "firstRowTop"}
                >
                  {hexItem.data.slice(5).map((item: any) => {
                    return (
                      <div>
                        <Hexagon
                          onClick={() => onclick(item)}
                          style={{ stroke: "#fee9ff", "stroke-width": 10 }}
                          backgroundImage={item.image}
                          diagonal={100}
                          className={"hexagon"}
                          backgroundScale={0.0}
                        />
                      </div>
                    );
                  })}
                </div>
                <Modal
                  open={IMGModal}
                  centered
                  // title="Title"
                  // onOk={handleOk}
                  onCancel={oncancelmodal}
                  footer={null}
                  className="modal_class_head"
                >
                  <div className="on_click">
                    <div className="position_modal_image">
                      <img className="modal_image" src={ModalImg} alt="" />
                      <CloseOutlined
                        onClick={() => {
                          oncancelmodal();
                        }}
                        className="cross_modal"
                      />
                    </div>
                  </div>
                </Modal>
              </>
            );
          })}
        </div>
      )}
    </>
  );
}
