import React, { Component, useEffect, useState } from "react";

import { Col, notification, Row } from "antd";
import { HomeOutlined, MobileOutlined, MailOutlined } from "@ant-design/icons";
import emailjs from '@emailjs/browser';
import Loader from "./Loader";

export default function Contact() {
  const [loading, setloading] = useState(false);
  const [validation, setvalidation] = useState({
    message: "",
    name: "",
    email: "",
    subject: "",
    file: "",
  });
  const [contact, setcontact] = useState({
    message: "",
    name: "",
    email: "",
    subject: "",
    file: "",
  });

  const validate = () => {
    if (contact.message === "") {
      validation.message = "Please Enter Message ";
    } else {
      validation.message = "";
    }

    if (contact.name === "") {
      validation.name = "Please Enter Name";
    } else {
      validation.name = "";
    }

    if (contact.email === "") {
      validation.email = "Please Enter Email";
    } else {
      validation.email = "";
    }

    if (contact.subject === "") {
      validation.subject = "Please Enter Subject";
    } else {
      validation.subject = "";
    }

    if (contact.file === "") {
      validation.file = "Please Upload Resume";
    } else {
      validation.file = "";
    }
  };

  const addMessage = (e: any) => {
    let values = e.target.value
    setcontact({ ...contact, message: values })
    console.log("@@@ event mess", e.target.value);
    validate()
  };

  const addName = (e: any) => {
    let values = e.target.value
    setcontact({ ...contact, name: values })
    console.log("@@@ event name", contact);
    validate()
  };

  const addEmail = (e: any) => {
    let values = e.target.value
    setcontact({ ...contact, email: values })
    console.log("@@@ event email", contact);
    validate()
  };

  const addSubject = (e: any) => {
    let values = e.target.value
    setcontact({ ...contact, subject: values })
    console.log("@@@ event subject", contact);
    validate()
  };

  const addfile = (e: any) => {
    let values = e.target.value
    setcontact({ ...contact, file: values })
    console.log("@@@ event file", contact);
    validate()
  };

  const openNotificationWithIcon = () => {
    notification["error"]({
      message: "Please Update Details",
    });
  };

  const clearFields = () => {
    setcontact({
      message: "",
      name: "",
      email: "",
      subject: "",
      file: "",
    })
  }

  const onEmailSend = () => {
    setloading(true);
    const templateParams = {
      from_name: contact.name,
      message: contact.message,
      to_name: 'Nancy',
      from_email: contact.email,
      subject: contact.subject
    };

    emailjs.send('service_4n0dy84', 'template_wnqjybs', templateParams, '6P86TlpeZ32g10oJa')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        notification["success"]({
          message: "You request has been sent successfully, we will revert you back.",
        });
        clearFields();
        setloading(false);
      }, (err) => {
        console.log('FAILED...', err);
        notification["error"]({
          message: "Failed to submit the details, please try again later.",
        });
        setloading(false);
      });
  }

  const onSubmit = () => {
    if (contact.email === "" && contact.message === "" && contact.subject === "" && contact.name === "") {
      openNotificationWithIcon()
    } else {
      onEmailSend();
    }
  }

  useEffect(() => {
    document.title = "SoftOps - Contact";
    {
      redirectToOrderHistory();
    }
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 1000);
  }, []);

  const redirectToOrderHistory = () => {
    if (window.location.href.includes("/contact")) {
      window.scrollTo(850, 0);
    }
  };

  const renderContactForm = () => {

    return (
      <>
        <Row className="container">
          <Col lg={24} md={24} xs={24} sm={24}>
            <div className="contact_body">
              <div>
                <h2
                  className="services-area"
                // data-aos="fade-down"
                // data-aos-duration="500"
                >
                  Contact Us
                </h2>
              </div>

              <div className="map-area">
                <iframe
                  width="100%"
                  height="600"
                  scrolling="no"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Softops%201019/30,%20Kesar%20Vihar,%20Naubasta,%20Kanpur,%20Uttar%20Pradesh%20208021+(SoftOps%20Technologies)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                  <a href="https://www.maps.ie/distance-area-calculator.html">
                    measure acres/hectares on map
                  </a>
                </iframe>
              </div>

              <div className="contact_form_box">
                <h2 className="contact-title">Get in Touch</h2>

                <div className="contact_flex_form">
                  <div className="contact_form">
                    <div className="form-group">
                      <textarea
                        className="form_control_textarea"
                        rows={15}
                        name="message"
                        id="message"
                        placeholder=" Enter Message"
                        onChange={addMessage}
                        value={contact.message}
                      ></textarea>
                    </div>
                    {validation.message !== "" && (
                      <text className="text-danger">{validation.message}</text>
                    )}<br />
                    <div className="name_email_group">
                      <input
                        className="form_control_name"
                        name="name"
                        id="name"
                        type="text"
                        placeholder="Enter your name"
                        onChange={addName}
                        value={contact.name}
                      />
                      <br />
                      {validation.name !== "" && (
                        <text className="text-danger">{validation.name}</text>
                      )}<br />
                      <input
                        className="form_control_email"
                        name="email"
                        id="email"
                        type="email"
                        placeholder="Email"
                        onChange={addEmail}
                        value={contact.email}
                      />
                      <br />
                      {validation.email !== "" && (
                        <text className="text-danger">{validation.email}</text>
                      )}<br />
                    </div>
                    <input
                      className="form_control_subject"
                      name="subject"
                      id="subject"
                      type="text"
                      placeholder="Enter Subject"
                      onChange={addSubject}
                      value={contact.subject}
                    />
                    <br />
                    {validation.subject !== "" && (
                      <text className="text-danger">{validation.subject}</text>
                    )}<br />
                    <button onClick={onSubmit} type="submit" className="contact_form_btn">
                      Send
                    </button>
                  </div>

                  <div className="contact_info_data">
                    <div className="media_contact-info">
                      <p className="contact-info__icon">
                        <HomeOutlined />
                      </p>
                      &nbsp;&nbsp;&nbsp;
                      <div className="media-body">
                        <h3>Address:- Satguru Parinay,5th floor (506,507)
                          PVVR+MPC, AB Rd, Scheme 54 PU4, Indore, 452011</h3>
                        {/* <p>Rosemead, CA 91770</p> */}
                      </div>
                    </div>
                    <div className="media_contact-info">
                      <p className="contact-info__icon">
                        <MobileOutlined />
                      </p>
                      &nbsp;&nbsp;&nbsp;
                      <div className="media-body">
                        <h3>0731-4009753 (HR)</h3>
                        <p>Mon to Fri 10am to 7pm</p>
                      </div>
                    </div>
                    <div className="media_contact-info">
                      <p className="contact-info__icon">
                        <MailOutlined />
                      </p>
                      &nbsp;&nbsp;&nbsp;
                      <div className="media-body">
                        <h3>nancy@softopstechnologies.com (HR)</h3>
                        <p>Send us your query anytime!</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <div>
      {loading ? (
        <div className="spinner">
          <Loader />
        </div>
      ) : (
        <div> {renderContactForm()}</div>
      )}
    </div>
  );
}
