import { Carousel, Col, Row } from "antd";
import React, { Component } from "react";
// @ts-ignoreAOS.init();
import { Link } from "react-router-dom";
import {
  Availabel,
  AvailabelTop,
  AvailabelBottom,
  shape1,
  shape2,
  AppScreen1,
  AppScreen2,
  AppScreen3,
  AppScreen4,
  AppScreen5,
  AppScreen6,
  AppScreen7,
  AppScreen8,
  AppScreen9,
  AppScreen10,
} from "../assets/assests";
const Data = [
  { image: AppScreen1 },
  { image: AppScreen2 },
  { image: AppScreen3 },
  { image: AppScreen4 },
  { image: AppScreen5 },
  { image: AppScreen6 },
  { image: AppScreen7 },
  { image: AppScreen8 },
  { image: AppScreen9 },
  { image: AppScreen10 }
]
export class ContactHeroMain extends Component {

  renderAppAvailabelForAllDevices = () => {
    return (
      <section className="available-app-area">
        <div className="container">
          <Row className="row d-flex justify-content-between">
            <Col lg={12} md={12}>
              <div className="app-caption">
                <div className="section-tittle section-tittle3">
                  <h2 data-aos="zoom-in">
                    SoftOps Developed Applications  <br /> <br />
                  </h2>
                  {/* <p>Please Check devloped App</p> */}
                  <div className="app-btn" data-aos="zoom-in" data-aos-duration="2500">
                    <div className="owl-carousel ">
                      <Carousel slidesToShow={4} autoplay className="hidetext" >
                        {Data.map((item: any) => {
                          return (
                            <div>
                              <img src={item.image} alt="" />
                            </div>
                          )
                        })}
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12} md={12} data-aos="fade-up" data-aos-duration="3000">
              <div className="app-img">
                <img src={Availabel} alt="" />
              </div>
            </Col>
          </Row>
        </div>
        {/* <!-- Shape --> */}
        <div className="app-shape">
          <img
            src={AvailabelTop}
            alt=""
            className="app-shape-top heartbeat d-none d-lg-block"
          />
          <img
            src={AvailabelBottom}
            alt=""
            className="app-shape-left d-none d-xl-block"
          />
          {/* <!-- <img src="assets/img/shape/app-shape-right.png" alt="" className="app-shape-right bounce-animate "> --> */}
        </div>
      </section>
    );
  };

  renderSayHello = () => {

    return (
      <section className="say-something-aera pt-90 pb-90 fix">
        <div className="container">
          <Row className="row justify-content-between align-items-center">
            <Col lg={16} md={22} >
              <div className="say-something-cap">
                <h2 data-aos="fade-up">Say Hello To The Collaboration Hub.</h2>
              </div>
            </Col>
            <Col lg={8} md={2} style={{ zIndex: "99" }} >
              <div className="say-btn" data-aos="fade-up">
                <Link to="/contact" className="btn radius-btn">
                  Contact Us
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        {/* <!-- shape --> */}
        <div className="say-shape">
          <img
            src={shape1}
            alt=""
            className="say-shape1 rotateme d-none d-xl-block"
          />
          <img src={shape2} alt="" className="say-shape2 d-none d-lg-block" />
        </div>
      </section>
    );
  };

  render() {
    return (
      <>
        {this.renderAppAvailabelForAllDevices()}

        {this.renderSayHello()}
      </>
    );
  }
}

export default ContactHeroMain;
