import React,{useEffect, useState} from 'react';
import './App.css';
import WebRoutes from './WebRoutes';
import 'antd/dist/antd.min.css';


function App() {
  const[loading, setloading] = useState(false);
  useEffect(()=>{
    setloading(true)
    setTimeout(() => {
      setloading(false)
    }, 1000);
  },[])
  return (
    <div className="App">
     <WebRoutes/>
    </div>
  );
}

export default App;
