// Customizable Area Start
export const LOGO = require("../assets/img/logo/SoftopsSymbol.png");
export const ReactLogo = require("../assets/img/logo/React-icon.png");
export const LOGODown = require("../assets/img/logo/SoftopsLogoWhite.png");
export const HeroImage = require("../assets/img/hero/hero_right.png");
export const FeaturesImg = require("../assets/img/shape/best-features.png");
export const App1 = require("../assets/img/gallery/bandibytz.png");
export const App2 = require("../assets/img/gallery/bandibytzzz.png");
export const App3 = require("../assets/img/gallery/bigbargains.png");
export const shap1 = require("../assets/img/shape/man1.png");
export const shap2 = require("../assets/img/shape/man2.png");
export const shap3 = require("../assets/img/shape/man3.png");
export const Availabel = require("../assets/img/shape/available-app.png");
export const AvailabelTop = require("../assets/img/shape/app-shape-top.png");
export const AvailabelBottom = require("../assets/img/shape/app-shape-left.png");
export const shape1 = require("../assets/img/shape/say-shape-left.png");
export const shape2 = require("../assets/img/shape/say-shape-right.png");
export const btnIcon = require("../assets/img/shape/form_icon.png");
export const SOFTOPSTECH = require("../assets/img/SOFTOPSTECH.png");
export const LoaderGif = require("../assets/img/1amw.gif");
export const AppScreen1 = require("../assets/img/CreatedApp/01.png");
export const AppScreen2 = require("../assets/img/CreatedApp/02.png");
export const AppScreen3 = require("../assets/img/CreatedApp/03.png");
export const AppScreen4 = require("../assets/img/CreatedApp/04.png");
export const AppScreen5 = require("../assets/img/CreatedApp/05.png");
export const AppScreen6 = require("../assets/img/CreatedApp/06.png");
export const AppScreen7 = require("../assets/img/CreatedApp/07.png");
export const AppScreen8 = require("../assets/img/CreatedApp/08.png");
export const AppScreen9 = require("../assets/img/CreatedApp/09.png");
export const AppScreen10 = require("../assets/img/CreatedApp/10.png");
export const App4 = require("../assets/img/gallery/adihex1.png");
export const App5 = require("../assets/img/gallery/Awake.png");
export const App7 = require("../assets/img/gallery/farmikart11.png");
export const App8 = require("../assets/img/gallery/farmikart111.png");
export const App9 = require("../assets/img/gallery/leanshubbb.png");
export const App10 = require("../assets/img/gallery/lenshub.png");
export const App11 = require("../assets/img/gallery/pakosiii.png");
export const App12 = require("../assets/img/gallery/parkosssiiii.png");
export const App13 = require("../assets/img/gallery/prepmonkey.png");
export const App14 = require("../assets/img/gallery/prepmonkey1.png");
export const App15 = require("../assets/img/gallery/prepmonkey111.png");
// Team--
export const Team1 = require("../assets/img/team/Akhil.jpg");
export const Team2 = require("../assets/img/team/akhilesh_jaiswal.jpg");
export const Team3 = require("../assets/img/team/Anmol_Karotiya.jpg");
export const Team4 = require("../assets/img/team/sumitbarfa.jpg");
export const Team5 = require("../assets/img/team/Gaurav_Singh.jpg");
export const Team6 = require("../assets/img/team/Himanshu_Joshi.jpg");
export const Team7 = require("../assets/img/team/Vivek.jpg");
export const Team8 = require("../assets/img/team/Hitesh.jpg");
export const Team9 = require("../assets/img/team/Nancy.jpg");
export const Team10 = require("../assets/img/team/Rituraj.jpg");
export const Team11 = require("../assets/img/team/Rohit_Yadav.jpg");
export const Team12 = require("../assets/img/team/Saubhagya.jpg");
export const Team14 = require("../assets/img/team/Nimish_Vikhar.jpg");
export const Team15 = require("../assets/img/team/Rahulkirar.jpg");
export const Team16 = require("../assets/img/team/Ravi.jpg");
export const Team17 = require("../assets/img/team/Shalini_Prajapati.jpg");
export const Team18 = require("../assets/img/team/Sonali_Solanki.jpg");
export const Team19 = require("../assets/img/team/Sumit_Belsare.jpg");
export const Team20 = require("../assets/img/team/SumitChoudhary.jpg");
export const Team21 = require("../assets/img/team/Devendra.jpg");
export const Team22 = require("../assets/img/team/Hitendra.jpg");
export const Team24 = require("../assets/img/team/MadhuriBourasi.jpg");
export const Team25 = require("../assets/img/team/shalini.jpg");
export const Team26 = require("../assets/img/team/Prachi_Gehlot.jpg");
export const Team27 = require("../assets/img/team/Lily_Ora.jpg");
export const Team28 = require("../assets/img/team/Khus_Singhal.jpg");
export const Team29 = require("../assets/img/team/Himanshu Rathore.jpg");
export const Team30 = require("../assets/img/team/Deepali.jpg");
export const Team31 = require("../assets/img/team/Ravi_Yadav.jpg");
export const Team32 = require("../assets/img/team/Sawan_Saini.jpg");
export const Team33 = require("../assets/img/team/abhilashPhoto.jpg");
export const Team34 = require("../assets/img/team/Diksha_patel.jpg");
export const Team35 = require("../assets/img/team/Sakshi_sahu.jpg");
export const Team36 = require("../assets/img/team/Adarsh_Pandey.jpg");
export const Team37 = require("../assets/img/team/Pankaj.jpg");
export const Team38 = require("../assets/img/team/Chetna.jpg");
export const Team39 = require("../assets/img/team/vaishali_kevat.png");
export const Team40 = require("../assets/img/team/RudranshSolanki.jpg");
export const Team41 = require("../assets/img/team/DevendraPratapSingh.jpg");
export const Team42 = require("../assets/img/team/AdityaSingh.jpg");
export const Team43 = require("../assets/img/team/PravinPrajapati.jpg");
export const Team44 = require("../assets/img/team/AnuragKanugo.jpg");
export const Team45 = require("../assets/img/team/sonam.jpg");
export const Team46 = require("../assets/img/team/adarshS.jpg");
export const Team47 = require("../assets/img/team/TarunKumar.jpg");
export const Team48 = require("../assets/img/team/Shaleen.jpg");
export const Team49 = require("../assets/img/team/chandrabhan.jpg");
export const Team50 = require("../assets/img/team/roshniYadav.jpg");
export const Team51 = require("../assets/img/team/yashVerma.jpg");
// export const Team52 = require("../assets/img/team/arunPawar.jpg");
export const Team53 = require("../assets/img/team/shefaliShau.jpg");
export const Team54 = require("../assets/img/team/ShraddhaChoudhary.jpg");
export const Team55 = require("../assets/img/team/PriyankaBahuguna.jpg");
export const Teampic1 = require("../assets/img/team/teampic1.jpg");
export const Teampic2 = require("../assets/img/team/teampic2.jpg");
export const Teampic3 = require("../assets/img/team/photo1.jpg");
export const Teampic4 = require("../assets/img/team/photo2.jpg");;
export const Teampic6 = require("../assets/img/team/photo6.jpg");
export const Teampic7 = require("../assets/img/team/IMG_0307.jpg");
export const Teampic8 = require("../assets/img/team/IMG_0315.jpg");
export const Teampic9 = require("../assets/img/team/IMG_0317.jpg");
export const Teampic10 = require("../assets/img/team/166.jpg");
export const Teampic11 = require("../assets/img/team/167.jpg");
export const Teampic12 = require("../assets/img/team/168.jpg");
export const Teampic13 = require("../assets/img/team/169.jpg");
export const Teampic14 = require("../assets/img/team/170.jpg");
export const Teampic15 = require("../assets/img/team/171.jpg");
export const Teampic16 = require("../assets/img/team/172.jpg");
export const Teampic17 = require("../assets/img/team/173.jpg");
export const Teampic18 = require("../assets/img/team/174.jpg");
export const Teampic19 = require("../assets/img/team/175.jpg");
export const Teampic20 = require("../assets/img/team/176.jpg");
export const Teampic21 = require("../assets/img/team/177.jpg");
export const Teampic22 = require("../assets/img/team/178.jpg");
export const Teampic23 = require("../assets/img/team/179.jpg");
export const Teampic24 = require("../assets/img/team/180.jpg");
export const Teampic25 = require("../assets/img/team/181.jpg");
export const Teampic26 = require("../assets/img/team/182.jpg");
export const Teampic27 = require("../assets/img/team/183.jpg");
export const Teampic28 = require("../assets/img/team/184.jpg");
export const Teampic29 = require("../assets/img/team/185.jpg");
export const Teampic30 = require("../assets/img/team/186.jpg");
export const Teampic31 = require("../assets/img/team/187.jpg");
export const Teampic32 = require("../assets/img/team/188.jpg");
export const Teampic33 = require("../assets/img/team/189.jpg");
export const Teampic34 = require("../assets/img/team/190.jpg");
export const Teampic35 = require("../assets/img/team/191.jpg");
export const Teampic36 = require("../assets/img/team/192.jpg");
export const Teampic37 = require("../assets/img/team/193.jpg");
export const Teampic38 = require("../assets/img/team/194.jpg");
export const Teampic39 = require("../assets/img/team/195.jpg");
export const Teampic40 = require("../assets/img/team/196.jpg");
export const Teampic41 = require("../assets/img/team/197.jpg");
export const Teampic42 = require("../assets/img/team/198.jpg");
export const Teampic43 = require("../assets/img/team/199.jpg");
export const Teampic44 = require("../assets/img/team/200.jpg");
export const Teampic45 = require("../assets/img/team/201.jpg");
export const Teampic46 = require("../assets/img/team/202.jpg");
export const Teampic47 = require("../assets/img/team/203.jpg");
export const Teampic48 = require("../assets/img/team/204.jpg");
export const Teampic49 = require("../assets/img/team/205.jpg");
export const Teampic50 = require("../assets/img/team/206.jpg");
export const Teampic51 = require("../assets/img/team/207.jpg");
export const Teampic52 = require("../assets/img/team/208.jpg");
export const Teampic53 = require("../assets/img/team/209.jpg");
export const Teampic54 = require("../assets/img/team/210.jpg");
export const Teampic55 = require("../assets/img/team/211.jpg");
export const Teampic56 = require("../assets/img/team/212.jpg");
export const Teampic57 = require("../assets/img/team/213.jpg");
export const Teampic58 = require("../assets/img/team/214.jpg");
export const Teampic59 = require("../assets/img/team/215.jpg");
export const Teampic60 = require("../assets/img/team/216.jpg");
export const Teampic61 = require("../assets/img/team/217.jpg");
// Customizable Area End