import { MenuOutlined } from '@ant-design/icons';
import React, { Component, useEffect, useRef, useState } from 'react';
import { LOGO } from "../assets/assests";
// @ts-ignore
import { Link as Links } from "react-scroll";
// @ts-ignore
import { Link } from 'react-router-dom';
function Header() {


  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const headerRef = useRef(null);


  const handleScroll = (elTopOffset: any, elHeight: any) => {
    if (window.pageYOffset > (elTopOffset + elHeight)) {
      setSticky({ isSticky: true, offset: elHeight });
    } else {
      setSticky({ isSticky: false, offset: 0 });
    }
  };

  useEffect(() => {
    // @ts-ignore 
    var header = headerRef.current.getBoundingClientRect();
    const handleScrollEvent = () => {
      handleScroll(header.top, header.height)
    }

    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);

  return (
    <nav id="sticky-header"
      //  className="navbar navbar-expand-lg nav_main"
      className={`navbar${sticky.isSticky ? ' navbar-expand-lg nav_main sticky' : ' navbar-expand-lg nav_main'}`}
      ref={headerRef}>
      <div className="container header_main" >
        <Link to="/">
          <a className="navbar-brand"> <img src={LOGO} className="logo"></img> </a>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          {/* <span className="navbar-toggler-icon"></span> */}
          <MenuOutlined />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 nav_class">
            <li className="nav-item">
              <Link to="/" className={window.location.pathname === "/" ? "nav-link_underline" : "nav-link"} aria-current="page" >Home</Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className={window.location.pathname === "/about" ? "nav-link_underline" : "nav-link"} aria-current="page">About US</Link>
            </li>
            <li className="nav-item">
              <Link to="/meet-the-team" className={window.location.pathname === "/meet-the-team" ? "nav-link_underline" : "nav-link"} aria-current="page" >Meet The Team</Link>
            </li>
            <Links to="#Services">
              <li className="nav-item">
                <Link className={window.location.pathname === "/services" ? "nav-link_underline" : "nav-link"} aria-current="page" to="/services">
                  Services
                </Link>
              </li>
            </Links>
            <li className="nav-item">
              <Link to="/career" className={window.location.pathname === "/career" ? "nav-link_underline" : "nav-link"} >Career</Link>
            </li>
            <li className="nav-item">
              <Link to="/gallery" className={window.location.pathname === "/gallery" ? "nav-link_underline" : "nav-link"} aria-current="page">Gallery</Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className={window.location.pathname === "/contact" ? "nav-link_underline" : "nav-link"} >Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header;