import { Col, Row } from "antd";
import React, { Component } from "react";
// @ts-ignore
import { Link } from 'react-router-dom';
import {
  LOGO,
  btnIcon,
  LOGODown
} from "../assets/assests";


export class Footer extends Component {

  renderFooter = () => {
    return (
      <footer>
        <div className="footer-area footer-padding">
          <div className="container">
            <Row className="row  justify-content-between">
              <Col lg={6} data-aos="fade-up" data-aos-duration="1000">
                <div className="single-footer-caption mb-30">
                  {/* <!-- logo --> */}
                  <div className="footer-logo">
                    <Link to="/">
                      <img src={LOGODown} alt="" />
                    </Link>
                  </div>
                  <div className="footer-tittle">
                    <div className="footer-pera">
                      <p className="info1">
                        SoftOps Technologies is a trusted and highly accredited organisation that provides IT consultancy services and technology solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6} data-aos="fade-up" data-aos-duration="1000">
                <div className="single-footer-caption mb-50">
                  <div className="footer-tittle">
                    <h4>Quick Links</h4>
                    <ul>
                      <li>
                        <Link to="/about">
                          <a href="javascript:void(0);">About</a>
                        </Link>
                      </li>
                      <li>
                        <Link to="/meet-the-team">
                          <a href="javascript:void(0);">Meet The Team</a>
                        </Link>
                      </li>
                      <li>
                        <Link to="/services">
                          <a href="javascript:void(0);">Services</a>
                        </Link>
                      </li>
                      <li>
                        <Link to="/career">
                          <a href="javascript:void(0);">Career</a>
                        </Link>
                      </li>
                      <li>
                        <Link to="/gallery">
                          <a href="javascript:void(0);">Gallery</a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={6} data-aos="fade-up" data-aos-duration="1500">
                <div className="single-footer-caption mb-50">
                  <div className="footer-tittle">
                    <h4>Platforms</h4>
                    <ul>
                      <li>
                        <a href="javascript:void(0);">Android/IOS</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">Hybrid/Native Applications</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">Web Application</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">ROR(Ruby On Rails)</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">QA</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={6} data-aos="fade-up" data-aos-duration="1500">
                <div className="single-footer-caption mb-50">
                  <div className="footer-tittle">
                    <h4>Newsletter</h4>
                    <div className="footer-pera footer-pera2">
                      <p>
                        Heaven fruitful doesn't over lesser in days Appear.{" "}
                      </p>
                    </div>
                    {/* <!-- Form --> */}
                    <div className="footer-form">
                      <div id="mc_embed_signup">
                        <form
                          target="_blank"
                          action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01"
                          method="get"
                          className="subscribe_form relative mail_part"
                        >
                          <input
                            type="email"
                            name="EMAIL"
                            id="newsletter-form-email"
                            placeholder=" Email Address "
                            className="placeholder hide-on-focus"
                          />
                          <div className="form-icon">
                            <Link to="/contact">
                              <button
                                type="submit"
                                name="submit"
                                id="newsletter-submit"
                                className="email_icon newsletter-submit button-contactForm"
                              >
                                <img src={btnIcon} alt="" />
                              </button>
                            </Link>
                          </div>
                          <div className="mt-10 info"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <!-- Copy-Right --> */}
            <Row className="row align-items-center">
              <div className="col-xl-12 ">
                <div className="footer-copy-right">
                  <p>
                    {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                    Copyright ©
                    {/* <script>document.write(new Date().getFullYear());</script> */}
                    {new Date().getFullYear()} All rights reserved | Design & Developed{" "}
                    <i className="ti-heart" aria-hidden="true"></i> by{" "}
                    <a href="javascript:void(0);" target="_blank">
                      SoftOps
                    </a>
                    {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                  </p>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </footer>
    );
  };


  render() {
    return (
      <>
        {this.renderFooter()}
      </>
    )

  }
}

export default Footer;