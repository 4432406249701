import { Carousel, Col, Row } from "antd";
import {  useEffect, useState } from "react";

import { Teampic1, Teampic2, Teampic3, Teampic4,Teampic55,Teampic57,Teampic6 } from "../assets/assests";
import Loader from "./Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import { EmployeListData } from "./Employe";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { Autoplay, EffectCoverflow } from "swiper";
export default function MeetTheTeam() {
  const [loading, setloading] = useState(false);
  useEffect(() => {
    document.title = "SoftOps - MeetTheTeam";
    {
      redirectToOrderHistory();
    }
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 1000);
  }, []);
  const redirectToOrderHistory = () => {
    if (window.location.href.includes("/meet-the-team")) {
      window.scrollTo(850, 0);
    }
  };
  const renderOurCustomerSection = () => {
    return (
      <section className="feature_section feature_section_meet_the_team">
        <div className="container">
          {/* <!-- Section Tittle --> */}
          <Row className="row d-flex justify-content-center">
            <div className="col-lg-6">
              <div className="section-tittle text-center">
                <h2>
                  Our Team
                  <br /> Our BackBone
                </h2>
              </div>
            </div>
          </Row>
          <Row className="row">
            <Col lg={24}>
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 20,
                  stretch: 0,
                  depth: 500,
                  modifier: 1,
                  slideShadows: true,
                }}
                pagination={true}
                modules={[Autoplay, EffectCoverflow]}
                loop={true}
                autoplay={{
                  delay: 700,
                  disableOnInteraction: false,
                }}
                className="mySwiper"
              >
                {EmployeListData.map((item: any, index: any) => {
                  console.log("@@@ index", index);

                  console.log("@@@ item", item);

                  return (
                    <SwiperSlide>
                      <div className="main_customer main_customer_meet">
                        <div className="our_customer_main our_customer_main_meet">
                          <div className="image_div_ourcustomer image_div_ourcustomer_team">
                            <img
                              src={item.image}
                              className="our_customer_main our_customer_main_meet our_customer_main_meet_img"
                              alt="App1"
                            />
                          </div>
                          <div className="what-cap">
                            <h4 className="devloper_name">
                              <a href="javascript:void(0);">{item.Name}</a>
                            </h4>
                            <p className="devloper_name_desig">
                              {item.Designation}
                            </p>
                            {/* <p className="devloper_name_desig_desc">
                             {item.feedback}
                            </p> */}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Col>
          </Row>
        </div>
      </section>
    );
  };
  const rendersOurCustomerSection = () => {
    return (
      <section className="feature_section">
        <div className="container">
          {/* <!-- Section Tittle --> */}
          <Row className="row">
            <Col lg={24}>
              <div className="owl-carousel ">
                <Carousel slidesToShow={1} className="hidetext" autoplay>
                <div className="main_customer main_customer_meet">
                    <img src={Teampic57} className="main_customer_meet_header" alt="App1" />
                  </div>
                <div className="main_customer main_customer_meet">
                    <img src={Teampic55} className="main_customer_meet_header" alt="App1" />
                  </div>
                  <div className="main_customer main_customer_meet">
                    <img src={Teampic1} className="main_customer_meet_header" alt="App1" />
                  </div>
                  <div className="main_customer main_customer_meet">
                    <img src={Teampic2} className="" alt="App1" />
                  </div>
                  <div className="main_customer main_customer_meet">
                    <img src={Teampic3} className="main_customer_meet_header" alt="App1" />
                  </div>
                  <div className="main_customer main_customer_meet">
                    <img src={Teampic4} className="main_customer_meet_header" alt="App1" />
                  </div>
                  <div className="main_customer main_customer_meet">
                    <img src={Teampic6} className="main_customer_meet_header" alt="App1" />
                  </div>
                </Carousel>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    );
  };
  return (
    <div>
      {loading ? (
        <div className="spinner">
          <Loader />
        </div>
      ) : (
        <>
          {rendersOurCustomerSection()}
          {renderOurCustomerSection()}
        </>
      )}
    </div>
  );
}
